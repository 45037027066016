// general
// ==========

.hint {
	padding-bottom: 20px;
	margin: 10px 0;
	border-bottom: 1px solid #eee;
}
.editor {
	float: left;
	width: 50%;
}
.preview {
	float: left; 
	margin-left: 4%;
	width: 46%;
}

blockquote {
	border-left: 5px solid #eee;
	margin-left: 20px;
	padding-left: 15px;
}

// code mirror
// ==========

.CodeMirror {
	background: rgba(0,0,0,.7);
	height: auto;
}
.CodeMirror-cursor {
	width: 1px;
	//background: rgba(255,255,255,.7);
	// this line comes later in the output css file than react-markdown's, so this takes priority (which is needed for cursor to show up on dark background)
	border-left: 1px solid rgba(255, 255, 255, 0.7);
}

.CodeMirror-overlayscroll-horizontal {
	height: 10px;
	> div {
		border-radius: 5px;
	}
}
.CodeMirror-overlayscroll-vertical {
	width: 10px;
	> div {
		border-radius: 5px;
	}
}

.CodeMirror-selected { background: rgba(255,255,255,.2); }
.CodeMirror-focused .CodeMirror-selected { background: rgba(255,255,255,.4); }
.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection { background: rgba(255,255,255,.4); }
.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection { background: rgba(255,255,255,.4); }

// Markdown Editor
// ==========

$button-gutter: 4px;

.MDEditor {
	cursor: auto;
	width: 100%;
}

.MDEditor_editor {
	border: 1px solid rgba(255,255,255,.2);
	border-radius: 4px;
	overflow: hidden;
	position: relative;
}

.MDEditor_toolbar {
	margin-bottom: $button-gutter;
	margin-top: $button-gutter;

	// actually, hide their's -- we'll just use our own
	display: none;
}

.MDEditor_toolbarButton {
	background: rgba(255,255,255,.4);
	border: 1px solid transparent;
	border-radius: 4px;
	cursor: pointer;
	display: inline-block;
	margin-right: $button-gutter;
	text-align: center;
	vertical-align: middle;
	width: 24px;
	height: 24px;
	padding: 4px 0;

	// transitions
	transition: all 180ms;
	-webkit-transition: all 180ms;

	&:hover,
	&:focus {
		background-color:rgba(255,255,255,.5);
		border-color: rgba(255,255,255,.1);
		box-shadow: 0 1px 1px rgba(255,255,255,.05);
		outline: none;
		transition: none;
		-webkit-transition: none;
	}
}

.MDEditor_toolbarButton:active,
.MDEditor_toolbarButton--pressed {
	background-color: rgba(255,255,255,.15);
	border-color: rgba(255,255,255,.1);
	box-shadow: inset 0 1px 1px rgba(255,255,255,.1);

	&:hover {
		background: rgba(0, 0, 0, 0.2);
	}
}

// Button Icons
.MDEditor_toolbarButton_icon {
	display: inline-block;
	height: 16px;
	width: 16px;
	margin: -1px 0 0 -1px;

	> svg {
		height: 16px;
		width: 16px;

		> path {
			fill: rgba(255,255,255,.7);
		}
	}
}

// hide the label when there's an icon
.MDEditor_toolbarButton_label {
	display: none;
}

// can't find decent icons for headings so just use text
.MDEditor_toolbarButton_label-icon {
	display: inline-block;
	font-size: 16px;
	font-weight: bold;
	line-height: .9;
	height: 16px;
	text-transform: uppercase;
}
@import "~web-vcore/Source/Utils/Styles/Entry_Symlinked.scss";

.background {
	background-image: url(/Images/Backgrounds/Planet_x1920.jpg);
	background-position: center center;
	background-size: cover;
}
@media (min-width: 1921px) {
	.background {
		background-image: url(/Images/Backgrounds/Planet_x3840.jpg);
	}
}

// quick fix for color-picker-box going off-screen in object-panel
.ObjectPanel .sketch-picker {
	position: absolute;
	right: -47px;
}

// ensure that message-boxes cannot be taller than the screen
/*.ReactModal__Content {
	max-height: calc(100% - 2000px);
}*/